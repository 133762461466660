<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Agreements
        </h3>
      </div>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ active: activeTab === 'pending' }" @click="setActiveTab('pending')">
          Pending
        </li>
        <li :class="{ active: activeTab === 'signed' }" @click="setActiveTab('signed')">
          Signed
        </li>
      </ul>
    </div>

    <div class="page-content">
      <div v-if="activeTab === 'pending'">
        <div class="row">
          <div class="col-6">
            <div class="search-wrapper">
              <img src="@/assets/icons/icon-search.svg" alt="Icon search">
              <input v-model="query" type="text" placeholder="Search">
            </div>
          </div>
        </div>
        <div class="table">
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>NMLS</th>
              <th>Pipeline</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="6">no records found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-6">
            <div class="search-wrapper">
              <img src="@/assets/icons/icon-search.svg" alt="Icon search">
              <input v-model="query" type="text" placeholder="Search">
            </div>
          </div>
        </div>
        <div class="table">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Phil Gustin</td>
                <td>pgustin@clearmortgagecapital.com</td>
                <td>
                  <button class="btn btn-primary">
                    Download
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreement',
  data() {
    return {
      query: '',
      activeTab: 'pending'
    }
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    }
  }
}
</script>
